import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import "../assets/components/bootstrap/css/bootstrap.min.css"

import "../assets/scss/theme.scss"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Icon1 from "../../static/images/icon1.svg"
import Icon2 from "../../static/images/icon2.svg"
import Icon3 from "../../static/images/icon3.svg"

const App = () => {
  const query = useStaticQuery(graphql`
    query {
      top: file(relativePath: { eq: "top_d.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      topMobile: file(relativePath: { eq: "top_m.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 550) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      cards: file(relativePath: { eq: "cards.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 960) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      students: file(relativePath: { eq: "Students.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 960) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  /**/

  return (
    <Layout>
      <SEO title="Home" />
      <header className="top-of-page" id="top">
        <div className="position-relative">
          <picture>
            <source
              type="image/webp"
              media="(min-width: 551px)"
              srcset="/images/static/e19c629ddb1c73485f7e9679832834d1/61be4/top_d.webp 480w,
/images/static/e19c629ddb1c73485f7e9679832834d1/1fb14/top_d.webp 960w,
/images/static/e19c629ddb1c73485f7e9679832834d1/25f09/top_d.webp 1920w,
/images/static/e19c629ddb1c73485f7e9679832834d1/2dd1d/top_d.webp 2494w"
              sizes="(max-width: 1920px) 100vw, 1920px"
            />
            <source
              media="(min-width: 551px)"
              srcset="/images/static/e19c629ddb1c73485f7e9679832834d1/9104c/top_d.jpg 480w,
/images/static/e19c629ddb1c73485f7e9679832834d1/a6352/top_d.jpg 960w,
/images/static/e19c629ddb1c73485f7e9679832834d1/d8255/top_d.jpg 1920w,
/images/static/e19c629ddb1c73485f7e9679832834d1/cbac6/top_d.jpg 2494w"
              sizes="(max-width: 1920px) 100vw, 1920px"
            />
            <source
              type="image/webp"
              srcset="/images/static/4f20746ee78346810b9780e049d9b4ab/c6300/top_m.webp 138w,
/images/static/4f20746ee78346810b9780e049d9b4ab/88818/top_m.webp 275w,
/images/static/4f20746ee78346810b9780e049d9b4ab/25338/top_m.webp 550w"
              sizes="(max-width: 550px) 100vw, 550px"
            />
            <source
              srcset="/images/static/4f20746ee78346810b9780e049d9b4ab/f81b6/top_m.jpg 138w,
/images/static/4f20746ee78346810b9780e049d9b4ab/cf463/top_m.jpg 275w,
/images/static/4f20746ee78346810b9780e049d9b4ab/dee3b/top_m.jpg 550w"
              sizes="(max-width: 550px) 100vw, 550px"
            />
            <img
              loading="lazy"
              sizes="(max-width: 1920px) 100vw, 1920px"
              srcset="/images/static/e19c629ddb1c73485f7e9679832834d1/9104c/top_d.jpg 480w,
/images/static/e19c629ddb1c73485f7e9679832834d1/a6352/top_d.jpg 960w,
/images/static/e19c629ddb1c73485f7e9679832834d1/d8255/top_d.jpg 1920w,
/images/static/e19c629ddb1c73485f7e9679832834d1/cbac6/top_d.jpg 2494w"
              src="/images/static/e19c629ddb1c73485f7e9679832834d1/d8255/top_d.jpg"
              alt=""
              style={{
                top: 0,
                left: 0,
                opacity: 1,
                width: "100%",
                height: "100%",
                objectFit: "cover",
                objectPosition: "center",
                minHeight: 450,
                maxHeight: 510,
              }}
            />
          </picture>
          {/* <Img
            fluid={[
              query.topMobile.childImageSharp.fluid,
              {
                ...query.top.childImageSharp.fluid,
                media: `(min-width: 551px)`,
              },
            ]}
            className="w-100"
            style={{ minHeight: 450, maxHeight: 510 }}
          /> */}
          <div
            className="w-100 position-absolute bg-dark-fade-35 text-center px-sm-6 px-4 px-md-12 py-4"
            style={{ left: 0, bottom: 0 }}
          >
            <h1 className="mb-3">It's all in the cards</h1>
            <div className="px-md-4">
              Scandinavian Card Issuing Services (SCIS) provides pre-paid, debit
              and credit card issuing and e-wallets for corporations and
              organisations. Through our agency partnerships with leading
              issuing banks in the European Union and the United States, SCIS
              can customize any card issuing solution to suit our clients’
              needs.
            </div>
          </div>
        </div>
      </header>

      <main className="main-container">
        <section id="services" className="section">
          <div className="container">
            <div className="row mb-4">
              <div className="col-12">
                <header className="text-center">
                  <h1 className="">Our services</h1>
                </header>
              </div>
            </div>

            <div className="row d-flex justify-content-center">
              <div className="col-12 col-md-4 text-center px-4 pb-2 py-md-2">
                <Icon1 className="px-sm-3 px-md-0 w-25" />
                <div className="mt-2">
                  <span className="fw-bold">Tailored solutions</span>
                  <br />
                  Through agency partnerships, SCIS provides white-label card
                  issuing solutions for any organisation that wishes to provide
                  employees or customers with their own card or e-wallet
                  programs.
                </div>
              </div>
              <div className="col-12 col-md-4 text-center px-4 py-2">
                <Icon2 className="px-sm-3 px-md-0 w-25" />
                <div className="mt-2">
                  <span className="fw-bold">Security first</span>
                  <br />
                  Cards and e-wallets can be provided for individuals and
                  corporations towards customary KYC approval and onboarding.
                  First line anti-money laundering and fraud checks are
                  performed automatically.
                </div>
              </div>
              <div className="col-12 col-md-4 text-center px-4 py-2">
                <Icon3 className="px-sm-3 px-md-0 w-25" />
                <div className="mt-2">
                  <span className="fw-bold">Digital usage</span>
                  <br />
                  Physical and virtual cards can be connected to services such
                  as Apple Pay and Samsung Pay for contactless payments and
                  withdrawals with a smartphone.
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="cards" className="section pt-0">
          <div className="container-fluid">
            <div className="row mb-4">
              <div className="col-12">
                <header className="text-center">
                  <h1 className="">Examples</h1>
                </header>
              </div>
            </div>
            <div
              className="row align-items-center"
              style={{ backgroundColor: "#7C7FA0" }}
            >
              <div className="col-12 col-md-6 p-0 order-2 order-md-1">
                <Img
                  fluid={query.students.childImageSharp.fluid}
                  className="cards-image-mh"
                />
              </div>
              <div className="col-12 col-md-6 pl-md-0 order-1 order-md-2">
                <div className="text-light px-4 px-lg-8 px-xl-10 py-4 py-md-0">
                  <span className="fw-bold">STUDENTCARD</span>
                  <br />
                  StudentCard is an upcoming card service tailored for European
                  students in the USA - a hassle-free, pre-paid debit card
                  without foreign exchange fees on purchases or withdrawals, or
                  the need for a local bank account in the US.
                </div>
              </div>
            </div>
            <div
              className="row  align-items-center"
              style={{ backgroundColor: "#D69630" }}
            >
              <div className="col-12 col-md-6 pr-md-0">
                <div className="text-light px-4 px-lg-8 px-xl-10 py-4 py-md-0">
                  <span className="fw-bold">CARD NOIR</span>
                  <br />
                  Black cards are a sign of prestige, purchasing power, priority
                  and esteem across the world. SCIS's Card Noir will be launched
                  this fall, and is a premium service service that provides
                  exclusive black plastic, metal or virtual cards combined with
                  select services to private individuals in the EU.
                </div>
              </div>
              <div className="col-12 col-md-6 p-0">
                <Img
                  fluid={query.cards.childImageSharp.fluid}
                  className="cards-image-mh"
                />
              </div>
            </div>
          </div>
        </section>
        <section id="more" className="section pt-0">
          <div className="container">
            <div className="row mb-4">
              <div className="col-12">
                <header className="text-center">
                  <h1 className="">Want to know more?</h1>
                </header>
              </div>
            </div>
            <div className="row">
              <div className="col-12 text-center">
                Is your organisation considering pre-paid or debit cards issuing
                to your employees or customers?
                <br />
                Contact us on:{" "}
                <a className="fw-bold" href="mailto:connect@scis.co">
                  connect@scis.co
                </a>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  )
}

export default App
